<template>
  <div>
    <div @contextmenu.prevent @click="antiScreenshotDisableClick">
      <transition name="TestContent">
        <div v-if="testStarted" class="w-full">
          <div class="w-full flex">
            <div class="flex flex-row" style="padding-top: 2rem; width: 80%">
              <div class="flex w-1/4 relative">
                <div class="flex flex-col bg-dark main-border h-fit fixed p-8" style="width: 16%; border-radius: 1rem">
                  <h3>{{ setData.name }}</h3>
                  <div class="flex flex-col mt-4">
                    <div class="flex flex-row items-center gap-x-2">
                      <font-awesome-icon icon="fa-regular fa-check-circle" class="h-6 w-6 text-success" />
                      <span class="flex flex-row items-center gap-x-2">
                        <h4>Answered:</h4>
                        <h4 class="text-dark">{{ currentQuestion - 1 }}</h4>
                      </span>
                    </div>

                    <div class="flex flex-row items-center gap-x-2 mt-3">
                      <font-awesome-icon icon="fa-regular fa-circle-xmark" class="h-6 w-6 text-danger" />
                      <span class="flex flex-row items-center gap-x-2">
                        <h4>Unanswered:</h4>
                        <h4 class="text-dark">{{ testData.totalQuestions - currentQuestion }}</h4>
                      </span>
                    </div>

                    <div class="flex flex-row items-center gap-x-2 mt-3">
                      <font-awesome-icon icon="fa-regular fa-clock" class="h-6 w-6 text-primary" />
                      <span class="flex flex-row items-center gap-x-2">
                        <h4>Time:</h4>
                        <h4 class="text-dark">{{ testTime }}</h4>
                      </span>
                    </div>
                  </div>

                  <div class="flex items-center gap-x-2 mt-10">
                    <div class="flex justify-end w-1/2">
                      <div class="OptionsButton w-full cursor-pointer" @click="optionsPopupActive = true">
                        <h3>Exit</h3>
                      </div>
                    </div>

                    <div class="flex justify-end w-1/2">
                      <div class="OptionsButton w-full cursor-pointer" @click="optionsPopupActive = true">
                        <h3>Options</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col justify-center items-center gap-y-6 w-3/4">
                <div v-for="(question, index) in testData.questions" :key="question" class="QuestionContainer">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-x-2">
                      {{ testData.answerWith.charAt(0).toUpperCase() + testData.answerWith.slice(1) }}
                      <font-awesome-icon
                        icon="fa-regular fa-volume-high"
                        @click="speak(question.term)"
                        class="h-5 w-5 text-dark cursor-pointer"
                      />
                    </div>

                    <div class="flex items-center gap-x-2">{{ index + 1 }} of {{ testData.totalQuestions }}</div>
                  </div>

                  <h3 v-if="testData.answerWith === 'term'" class="w-full mt-4">{{ question.term }}</h3>
                  <h4 v-else class="w-full font-light mt-4">{{ question.term }}</h4>

                  <div class="flex flex-col gap-y-4 mt-10">
                    <div
                      v-for="choice in question.answerChoices"
                      :key="choice"
                      @click="selectAnswer(index, choice)"
                      :class="{ AnswerChoiceButtonActive: testData.questions[index].selected === choice }"
                      class="AnswerChoiceButton cursor-pointer flex w-full text-dark main-border rounded-lg p-6"
                    >
                      <p v-if="testData.answerWith === 'term'" class="text-lg">{{ choice }}</p>
                      <p v-else class="text-lg font-semibold">{{ choice }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="ConfigurePopupOverlay">
        <div v-if="configurePopupActive" class="ConfigurePopupOverlay"></div>
      </transition>

      <transition name="ConfigurePopupOverlay">
        <div v-if="configurePopupActive" class="ConfigurePopup">
          <div class="PracticeMethod">
            <div class="flex justify-between p-12 main-bottom-border">
              <div class="flex w-2/3 items-start gap-x-4">
                <div class="flex flex-col h-full justify-between">
                  <div>
                    <h1 class="text-5xl font-bold">Set up your test</h1>
                    <h3 class="font-light">{{ setData?.name }}</h3>
                  </div>

                  <div class="flex items-center justify-between">
                    <div class="flex items-center justify-between gap-x-3">
                      <h3>Questions</h3>
                      <p>({{ termCount }} Possible)</p>
                    </div>
                    <div>
                      <vs-input-number v-model="configurePopupData.questionCount" type="number" color="success" min="1" :max="termCount" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end w-1/3">
                <img :src="setData?.thumbnail" width="125px" class="rounded-lg" />
              </div>
            </div>

            <div class="flex flex-col justify-between" style="height: 54%">
              <div class="flex items-start p-12 main-bottom-border">
                <div class="flex w-full gap-x-8">
                  <div
                    class="TestTypeButton cursor-pointer"
                    :class="{ TestTypeActive: configurePopupData.type === 'multiple_choice' }"
                    @click="configurePopupData.type = 'multiple_choice'"
                  >
                    <img :src="multipleChoiceImage" width="60px" />
                    <h1>Multiple Choice</h1>
                  </div>
                  <div
                    class="TestTypeButton cursor-pointer"
                    :class="{ TestTypeActive: configurePopupData.type === 'true_false' }"
                    @click="configurePopupData.type = 'true_false'"
                  >
                    <img :src="trueFalseChoiceImage" width="60px" height="60px" />
                    <h1>True or False</h1>
                  </div>
                </div>
              </div>

              <div class="flex w-full justify-end px-12">
                <div class="flex gap-x-4" style="width: 30%">
                  <vx-tooltip text="Term" position="top" class="select-none" color="#23853b">
                    <div
                      class="AnswerWithButton cursor-pointer rounded-lg bg-dark main-border"
                      @click="configurePopupData.answerWith = 'term'"
                      :class="{ AnswerWithButtonActive: configurePopupData.answerWith === 'term' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-message" class="h-6 w-6" />
                    </div>
                  </vx-tooltip>

                  <vx-tooltip text="Definition" position="top" class="select-none" color="#23853b">
                    <div
                      class="AnswerWithButton cursor-pointer rounded-lg bg-dark main-border"
                      @click="configurePopupData.answerWith = 'definition'"
                      :class="{ AnswerWithButtonActive: configurePopupData.answerWith === 'definition' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-file-lines" class="h-6 w-6" />
                    </div>
                  </vx-tooltip>
                </div>
                <div class="flex gap-x-4" style="width: 70%">
                  <vs-button
                    class="w-full h-fit font-bold cursor-pointer-match"
                    size="large"
                    icon="icon-arrow-left"
                    icon-pack="feather"
                    color="danger"
                    @click="$router.push('/study/select')"
                    >Cancel
                  </vs-button>

                  <vs-button
                    class="w-full h-fit font-bold cursor-pointer-match"
                    size="large"
                    icon="icon-file-plus"
                    icon-pack="feather"
                    icon-after
                    color="success"
                    @click="startTest"
                    >Start Test
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <div :class="(!studyPaused && studyAntiSkid) || !studyAntiSkid ? 'hidden' : 'block'" class="paused-overlay">
      <div class="flex items-center justify-center">
        <img src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPoint.png" width="400" alt="IP Logo" />
      </div>
      <h1 class="mt-10 font-bold text-center">You Are Currently Paused</h1>
      <h1 class="mt-4 font-light text-center">To Resume Tap In The Middle Of The Screen</h1>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      appData: null,
      setData: null,
      termResults: null,
      termCount: null,

      studyPaused: false,
      studyAntiSkid: true,
      keyDown: false,

      optionsPopupActive: false,
      configurePopupActive: false,
      configurePopupData: {
        questionCount: 0,
        answerWith: 'term',
        type: 'multiple_choice',
      },

      testData: {
        totalQuestions: 0,
        type: null,
        answerWith: null,
        questions: [],
      },

      testStarted: false,
      testTime: '0 Seconds',
      currentQuestion: 1,
      elapsedTime: 0,

      multipleChoiceImage: 'https://connect-cdn.intellectualpoint.com/assets/images/icons/study-tool-app/multiple-choice.png',
      trueFalseChoiceImage: 'https://connect-cdn.intellectualpoint.com/assets/images/icons/study-tool-app/true-false.png',
      voices: [],
      selectedVoiceId: 115,
      voiceSynth: null,
    };
  },
  components: {
    RadialProgressBar,
  },
  methods: {
    fetchSetDetails() {
      this.$http
        .get(`app/flashcards/${this.$route.params.setId}`)
        .then((response) => {
          if (response.status === 200) {
            this.setData = response.data;

            if (this.setData.terms.length === 0) {
              this.$vs.notify({
                title: 'Error While Loading Study Set',
                text: 'There are no terms in the set you are trying to practice',
                icon: 'feather',
                iconPack: 'icon-x-circle',
                color: 'danger',
                position: 'top-right',
              });
              return this.$router.push('/flashcards/select');
            }

            this.fetchTermsData();
          }
        })
        .catch(() => {});
    },
    fetchTermsData() {
      this.$http
        .get(`app/flashcards/${this.$route.params.setId}/terms?amount=1000&page=1&search=`)
        .then((response) => {
          const password = 'SUPERGOODPASSWORDWOWIFYOURREADINGTHISYOUWILLBESUED938+';
          const decrypted = JSON.parse(this.$CryptoJS.AES.decrypt(response.data.data, password).toString(this.CryptoJS.enc.Utf8));

          if (response.status === 200) {
            this.termResults = decrypted.terms;
            this.termCount = decrypted.count;
            this.configurePopupData.questionCount = this.termCount;
          }
        })
        .catch(() => {});
    },
    startTest() {
      this.testData.totalQuestions = parseInt(this.configurePopupData.questionCount);
      this.testData.type = this.configurePopupData.type;
      this.testData.answerWith = this.configurePopupData.answerWith;

      this.termResults = shuffle(this.termResults);

      if (this.testData.answerWith === 'term') {
        const shuffledTerms = this.termResults.sort(() => Math.random() - 0.5);
        const selectedTerms = shuffledTerms.slice(0, this.testData.totalQuestions);

        selectedTerms.forEach((term) => {
          let answerChoices = [];
          answerChoices.push(term.definition);

          const shuffledDefinitions = this.termResults
            .map((otherTerm) => otherTerm.definition)
            .filter((otherTerm) => otherTerm !== term.definition)
            .sort(() => Math.random() - 0.5);

          answerChoices = answerChoices.concat(shuffledDefinitions.slice(0, 3));

          this.testData.questions.push({
            term: term.term,
            answerChoices,
            answer: term.definition,
            selected: null,
          });
        });

        this.configurePopupActive = false;
        this.testStarted = true;
        this.startTestTimer();
      } else {
        const shuffledTerms = this.termResults.sort(() => Math.random() - 0.5);
        const selectedTerms = shuffledTerms.slice(0, this.testData.totalQuestions);

        selectedTerms.forEach((term) => {
          let answerChoices = [];
          answerChoices.push(term.term);

          const shuffledTerms = this.termResults
            .map((otherTerm) => otherTerm.term)
            .filter((otherTerm) => otherTerm !== term.term)
            .sort(() => Math.random() - 0.5);

          answerChoices = answerChoices.concat(shuffledTerms.slice(0, 3));

          this.testData.questions.push({
            term: term.definition,
            answerChoices,
            answer: term.term,
            selected: null,
          });
        });

        this.configurePopupActive = false;
        this.testStarted = true;
        this.startTestTimer();
      }
    },
    startTestTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime++;
        this.updateFormattedTime();
      }, 1000);
    },
    updateFormattedTime() {
      const minutes = Math.floor(this.elapsedTime / 60);
      const seconds = this.elapsedTime % 60;

      if (minutes > 0) {
        this.testTime = `${minutes}m ${seconds}s`;
      } else {
        this.testTime = `${seconds} Second${seconds !== 1 ? 's' : ''}`;
      }
    },
    selectAnswer(questionIndex, answer) {
      if (this.testData.questions[questionIndex].selected === null) {
        this.currentQuestion++;
      }

      this.testData.questions[questionIndex].selected = answer;
    },
    speak(text) {
      const utterance = new SpeechSynthesisUtterance();
      utterance.voice = this.voices[this.selectedVoiceId];
      utterance.text = text;
      utterance.volume = 1.0;
      utterance.pitch = 1.0;
      utterance.rate = 1.0;
      utterance.lang = 'en-US';

      this.voiceSynth.cancel();
      this.voiceSynth.speak(utterance);
    },

    antiScreenshotDisableClick() {
      if (this.studyPaused === true && this.keyDown === false) {
        this.studyPaused = false;
      }
    },
    antiPsEnable(e) {
      this.keyDown = false;
      if (e.keyCode === 44) {
        this.$copyText(
          'Taking Screenshots Is Not Allowed, Your Access Will Be Revoked If You Continue To Record, Screenshot or Share Simulations',
        );
        e.preventDefault();
      }
    },
    antiScreenshotEnable(e) {
      this.keyDown = true;
      if (e.keyCode === 123) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === '91'.charCodeAt(0) && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === '93'.charCodeAt(0) && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'H'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'A'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'F'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'C'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === 91) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 92) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 93) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.shiftKey && e.keyCode === 18) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 18) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 224 && e.shiftKey) {
        this.studyPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 17) {
        e.preventDefault();
      }
      if (e.keyCode === 18) {
        e.preventDefault();
      }
    },
    antiLeaveWindow() {
      this.studyPaused = true;
    },
    initializeVoices() {
      // const awaitVoices = new Promise((resolve) => (window.speechSynthesis.onvoiceschanged = resolve)).then(() => {
      //   this.voiceSynth = window.speechSynthesis;
      //   this.voices = this.voiceSynth.getVoices();
      //   console.log(this.voices);
      // });
    },
  },
  mounted() {
    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);

    setTimeout(() => {
      this.configurePopupActive = true;
    }, 500);

    this.fetchSetDetails();
    this.initializeVoices();

    if (this.$acl.check('proctor')) {
      this.studyAntiSkid = false;
      return;
    }

    window.addEventListener('blur', this.antiLeaveWindow);
    window.addEventListener('keydown', this.antiScreenshotEnable);
    window.addEventListener('keyup', this.antiPsEnable);
  },
  beforeDestroy() {
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);
    window.removeEventListener('keydown', this.antiScreenshotEnable);
    window.removeEventListener('keyup', this.antiPsEnable);
    window.removeEventListener('blur', this.antiLeaveWindow);
  },
};
</script>

<style scoped lang="scss">
.term-name {
  font-size: 22px;
}

@media (max-width: 750px) {
  .term-name {
    font-size: 18px;
  }
}

.ConfigurePopupOverlay-enter-active,
.ConfigurePopupOverlay-leave-active {
  transition: opacity 0.5s;
}

.ConfigurePopupOverlay-enter,
.ConfigurePopupOverlay-leave-to {
  opacity: 0;
}

.ConfigurePopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  z-index: 99998;
}

.ConfigurePopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 650px;
  height: 80%;
  max-height: 550px;
  border-radius: 2rem;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  column-gap: 4rem;
  background-color: #040815;
  border: 1px solid #181d2a;
}

.PracticeMethod {
  flex: 1;
  height: 100%;
  border-radius: 2rem;
  transition: all 0.5s cubic-bezier(0.35, 0.4, 0.25, 1);
  background-size: cover;
}

.TestTypeButton {
  display: flex;
  width: 50%;
  border-radius: 1.5rem;
  padding: 1.5rem;
  border: 2px solid #181d2a;
  background-color: #02050d;
  transition: all 0.3s ease-in-out;
  column-gap: 1.3rem;
  align-items: center;
}

.TestTypeActive {
  background-color: rgb(34, 201, 90, 0.1);
  border: 2px solid #22c759;
  -webkit-box-shadow: 0px 0px 20px 0px rgb(34, 201, 90, 0.5);
  -moz-box-shadow: 0px 0px 20px 0px rgb(34, 201, 90, 0.5);
  box-shadow: 0px 0px 20px 0px rgb(34, 201, 90, 0.5);
}

.AnswerWithButton {
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.AnswerWithButtonActive {
  background-color: rgb(34, 201, 90, 0.1);
  border: 2px solid #aaaaaa;
  -webkit-box-shadow: 0px 0px 12px 0px rgb(255, 255, 255, 0.5);
  -moz-box-shadow: 0px 0px 12px 0px rgb(255, 255, 255, 0.5);
  box-shadow: 0px 0px 12px 0px rgb(255, 255, 255, 0.5);
}

.OptionsButton {
  width: fit-content;
  padding: 0.8rem 2rem;
  border: 1px solid #aaaaaa;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  h3 {
    font-size: 1rem;
    font-weight: 700;
  }

  &:hover {
    border: 1px solid #fff;
    background-color: rgb(255, 255, 255, 0.1);
    -webkit-box-shadow: 0px 0px 12px 0px rgb(255, 255, 255, 0.5);
    -moz-box-shadow: 0px 0px 12px 0px rgb(255, 255, 255, 0.5);
    box-shadow: 0px 0px 12px 0px rgb(255, 255, 255, 0.5);
  }
}

.QuestionContainerWrapper {
  mask-image: linear-gradient(180deg, transparent 0.1%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 100%, transparent 1%);
}

.QuestionContainer {
  background-color: #02050d;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;
  border: 1px solid #181d2a;
}

.AnswerChoiceButton {
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid #455774;
  }
}

.AnswerChoiceButtonActive {
  border: 1px solid #31b952 !important;
  color: #fff !important;
}
</style>

<style>
.theme-dark .vs-navbar.vs-navbar-color-transparent:not(.vs-navbar-flat) {
  border: 1px solid transparent !important;
  padding: 2rem;
  border-bottom: 1px solid #181d2a !important;
}
</style>
